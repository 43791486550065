<template>
    <w-layout justify-center fill-height row wrap mx-3>
        <w-flex xs12 md6>
            <w-layout fill-height align-center justify-center>
                <SSOErrorImage width="80%" height="80%" />
            </w-layout>
        </w-flex>
        <w-flex xs12 md6>
            <slot>
                <w-layout fill-height px-3 align-center>
                    <w-flex v-t="{path: 'sso.signin_error', args: { name: vendor }}" shrink title />
                </w-layout>
            </slot>
        </w-flex>
    </w-layout>
</template>

<script>

export default {
	name: 'SignInSSOError',
    components: {
        SSOErrorImage: () => ({
            component: import('@/components/SignIn/SSOErrorImage')
        })
    },
    computed: {
		vendor: function () {
			return this.$route.params.vendor
        }
    }
}
</script>